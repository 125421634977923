
import Vue, { PropType } from "vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { DialogCloseType, DialogI, DialogOutput } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";
import Header from "@/components/v2/base/Header.vue";
import { UserPreferences } from "@prestonly/preston-common";
import UserProfileExerciseCheckboxes from "@/components/userProfile/UserProfileExerciseCheckboxes.vue";

export default Vue.extend({
  name: "ListeningAndWritingActivitiesDialog",
  components: {
    UserProfileExerciseCheckboxes,
    BaseDialog,
    Btn,
    Header,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  methods: {
    close() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
      });
    },
  },
});
